/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link, useNavigate } from 'react-router-dom';
/**Css styles */
import '../../Navigation.css';

import FilterResults from 'react-filter-search';

// Importing Uuid
import { v4 as uuidv4 } from 'uuid';

import app from '../../firebase';
import * as firebaseAdmin from 'firebase/auth'

const db = app.firestore();


export default function Tags() {
    // Navigation
    let navigate = useNavigate();


    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState([])
    const [parametroUsuario, setParametroUsuario] = useState('');
    const [currentUser, setCurrentUser] = useState({})
    const [currentTags, setCurrentTags] = useState([])
    const [isLoadingCurrentTags, setIsLoadingCurrentTags] = useState(false)
    const [limit, setLimit] = useState(10);

    const deleteUser = async (id, uid) => {
        if (window.confirm("¿Eliminar usuario?")) {

            alert("Eliminando usuario, por favor espere");
            await db.collection("usuarios").doc(id).delete().then(() => {



                firebaseAdmin.deleteUser(uid).then(() => {
                    console.log("User deleted from firebase auth")
                })


                db.collection("tags").where("userId", "==", id).get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        doc.ref.delete();
                    })
                })
                alert("Se ha eliminado el usuario y su información almacenada en la base de datos, también han sido eliminados sus tags")
            })
        } else {
            return false;
        }
    }

    const handleSetCurrentUser = (user) => {
        setIsLoadingCurrentTags(true)
        setCurrentUser(user)

        db.collection("persons").where("userId", "==", user.userId).get().then((query) => {
            let docs = [];
            query.forEach(doc => {
                docs.push({ ...doc.data(), id: doc.id });
            });
            setCurrentTags(docs)
            setIsLoadingCurrentTags(false)
        })
    }


    useEffect(() => {

        let adminIdAuth = sessionStorage.getItem('adminId')
        if (!adminIdAuth) {
            navigate('/ad/login')
        }

        db.collection('usuarios').where("userId", "==", adminIdAuth).get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                setUserData(doc.data());
            })
            setIsLoading(false);
        })

        db.collection('usuarios')
            .orderBy('userName', 'asc')
            .limit(limit || 10) // Limitar la consulta inicial a 10 correos electrónicos
            .onSnapshot((querySnapshot) => {

                const docs = [];
                querySnapshot.forEach(doc => {
                    docs.push({ ...doc.data(), id: doc.id })
                });

                setIsLoading(false);
                setUsers(docs)
            });
    }, [limit])
    return (
        <div className='container'>

            <div className='w-100'>

                <div className="my-4">
                    <span className="text-dark fw-bold display-6">Emails enviados</span>
                </div>
                <div className="flex my-4">
                    <Link to="/ad/dashboard" className="bg-dark text-decoration-none text-white bg-white p-2 fs-5">
                        ◄ Regresar
                    </Link>
                </div>
            </div>
            <div className='my-3 text-center bg-light-gray p-2 r-20'>
                <span>Total de usuarios: <strong>{users.length}</strong></span>
                <input placeholder="Buscar tagId" value={parametroUsuario} onChange={(text) => setParametroUsuario(text.target.value)} className="my-4 form-control shadow"></input>
            </div>

            <input
                type="number"
                placeholder={`Correos a mostrar`}
                value={limit}
                className='form-control mb-4 w-50-responsive'
                onChange={(e) => setLimit(e.target.value)}
            />


            <div className="table-responsive table-sm">

                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th scope="col" className='fs-12'>No.</th>
                            <th className='fs-12'>Correo electrónico</th>
                            <th className='fs-12'>Nombre de usuario</th>
                            <th className='fs-12'>Tags</th>
                            <th className='fs-12'>Acciones</th>
                        </tr>
                    </thead>



                    {
                        !users.length == false ?
                            <FilterResults
                                value={parametroUsuario}
                                data={users}
                                renderResults={results => (

                                    <tbody className='p-1'>

                                        {
                                            results.map((user, index) => (
                                                <tr key={index}>
                                                    <td scope="row" className='fs-12 fw-bold'>{index+1}</td>
                                                    <td className='fs-12'>{user.userEmail}</td>
                                                    <td className='fs-12'>{user.userName ? user.userName : "Desconocido"}</td>
                                                    <td className='fs-12'>
                                                        <button className='btn btn-outline-dark fs-12' onClick={() => handleSetCurrentUser(user)} data-bs-toggle="modal" data-bs-target="#modalVerTagsAsociados">Ver</button>
                                                    </td>
                                                    <td>
                                                        <button class="btn btn-danger fs-12" onClick={() => deleteUser(user.id, user.userId)}>Eliminar</button>
                                                    </td>
                                                </tr>
                                            ))
                                        }


                                    </tbody>

                                )}
                            />
                            :
                            <div className='iconNoNotificacions'></div>
                    }

                </table>
            </div>

            {/* Modal ver tags asociados */}
            <div className='d-flex justify-content-center'>
                <div class="modal fade" id="modalVerTagsAsociados" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modalCenter modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h2 class="titleText">Tags asociados de: <span className='text-dark fw-bold'>{currentUser.userName}</span></h2>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className='my-2 p-2 '>


                                    {
                                        isLoadingCurrentTags == true ?
                                            <div class="spinner-border text-dark" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                            :

                                            currentTags.length != 0 ?
                                                currentTags.map((tag) => (
                                                    <div className='bg-light-gray p-2 my-2'>
                                                        <a href={`/tag/${tag.tagId}`} target={"_blank"} className='text-decoration-underline text-dark fw-bold'>{tag.tagId}</a>
                                                    </div>
                                                ))
                                                :
                                                <div>No hay tags asociados</div>
                                    }
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button className="btn btn-dark" data-bs-toggle="modal" data-bs-dismiss="modal" onClick={() => setCurrentTags([])}>Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* Fin Modal ver tags asociados */}
        </div>



    )
}
