import { useEffect, useState } from 'react';
import { addSuscriptionDataToPet, getUserDataByUserId } from '../../../utils/suscriptions'
import { calcularFechaDeVencimiento, obtenerFechaEnFormato } from '../../../utils/tools';

const SuscriptionCardSemester = ({petId}) => {


    const getUserLiveAccessToken = async () => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Basic QVJ5SE9aYUE0dEMwak1kbHNPSnp3RXNPWUxDdURwUm9jUk1COEJxbnJXcm9CV0xIMXg5eGlLVUxIaXNmSk1JNjFzYnVMWTdEcUlyRDAzMWg6RU11WlllYmY3ZWp2QmRBcFQ1UFZnd0hSQkJtZ0hEZkYtSUpwVVdtSmhwN3MzZHBOVGhaSzFlOHdRV01Tc1poYzl2TzdkejV0dnlKdURFS1o=");
        myHeaders.append("Cookie", "datadome=zeZ7d32oNQCk1bA~Gmqo_ZoYFlsP1VOiumMnIAjsP1LvOzkkDfl2fOuHlDJFYvhQe~PG~U03J8WPXbDL_hlQlIRJB3wlxCe4v7~gc~k2yoanzEsPoO1AIfppRQoWhbsw");

        var urlencoded = new URLSearchParams();
        urlencoded.append("grant_type", "client_credentials");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        const response = await fetch("https://api-m.paypal.com/v1/oauth2/token?_ga=2.146082039.2100794126.1697754128-1369166931.1697754128", requestOptions);
        const result = await response.json();
        return result.access_token;
    }

    const getBearerAccessToken = async () => {

        const access_token = await getUserLiveAccessToken();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", `Basic ${access_token}`);

        var urlencoded = new URLSearchParams();
        urlencoded.append("grant_type", "client_credentials");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        const response = await fetch("https://api-m.paypal.com/v1/oauth2/token?_ga=2.197096236.1638088848.1692680276-2047062090.1685468217", requestOptions);
        const result = await response.json();
        return result.access_token;
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.paypal.com/sdk/js?client-id=AdrX2aVwg_tkg6_XvtGnVJxD1N_L0E35ARe0qEJgOXzwtyVvOodDCPMC5ZtZVnEOSG230_zg-lbmoq7O&vault=true&intent=subscription';
        script.async = true;
        script.onload = () => {
            window.paypal.Buttons({
                style: {
                    shape: 'pill',
                    color: 'gold',
                    layout: 'vertical',
                    label: 'subscribe'
                },
                createSubscription: function (data, actions) {
                    return actions.subscription.create({
                        /* Creates the subscription */
                        plan_id: 'P-3FR87554X29757308MYLA7WA'
                    });
                },
                onApprove: async function (data, actions) {

                    const fechaFormateada = obtenerFechaEnFormato();
                    const nextBillingTime = calcularFechaDeVencimiento('anual');

                    const subscriptionData = {
                        subscriptionStatus: "ACTIVE",
                        subscriptionID: data.subscriptionID,
                        subscription_startTime: fechaFormateada,
                        subscription_nextBillingTime: nextBillingTime
                    }
                    await addSuscriptionDataToPet(subscriptionData, "Anual", petId)
                }
            }).render('#paypal-button-container-P-3FR87554X29757308MYLA7WA'); // Renders the PayPal button
        };
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className='w-100' id="paypal-button-container-P-3FR87554X29757308MYLA7WA"></div>
    );
};

export default SuscriptionCardSemester;