import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';

// import { Editor, convertToHtml } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import htmlToDraft from 'html-to-draftjs';
// import { EditorState, convertToRaw, ContentState, Modifier } from 'draft-js';
// import draftToHtml from 'draftjs-to-html';


// TinyMCE
import { Editor } from '@tinymce/tinymce-react';
// Importing sweet alert
import Swal from 'sweetalert2';
// Firebase
import { serverTimestamp } from "firebase/firestore";

import app from '../../../firebase';
import CheckboxSelector from './CheckboxSelector/CheckboxSelector';
const db = app.firestore();

export default function EmailMarketing() {



    const editorRef = useRef(null);

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false
    })



    const [users, setUsers] = useState([])
    const [usersSelected, setUsersSelected] = useState([])
    const [showForm, setShowForm] = useState(false)
    //  const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [marketingEmails, setMarketingEmails] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [emailState, setEmailState] = useState({})
    const [externalMails, setExternalMails] = useState(false)
    const [externalMailsList, setExternalMailsList] = useState("")
    const [customHtml, setCustomHtml] = useState("")

    const handleSelection = (selectedUsers) => {
        if (selectedUsers.length > 0) {
            setUsersSelected(selectedUsers)
        }
    }

    const handleContinue = () => {
        setShowForm(true)
    }

    const handleExternalMails = () => {
        console.log("External mails::", externalMails)
        setExternalMails(!externalMails)
    }

    // const onEditorStateChange = (newEditorState) => {
    //     setEditorState(newEditorState);
    // }

    const handleEmailStateChange = (name, value) => {
        setEmailState({
            ...emailState,
            [name]: value
        })
    }

    const save = () => {
        try {
            setIsLoading(true);
            const content = editorRef.current.getContent();

            if (!externalMails) {
                usersSelected.forEach(user => {
                    if (user && user.userEmail) {
                        sendEmail(user, content);
                    }
                });
            } else {
                const emails = externalMailsList.split(",");
                emails.forEach(email => {
                    const user = { userEmail: email };
                    if (user && user.userEmail) {
                        sendEmail(user, content);
                    }
                });
            }

            Toast.fire({
                icon: 'success',
                title: 'Emails enviados correctamente'
            });
        } catch (error) {
            console.error("Error sending emails: ", error);
            db.collection("errors").add({
                timestamp: new Date(),
                errorDetails: error.message,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const sendEmail = async (user, htmlContent) => {
        try {
            await db.collection("mail").add({
                to: user.userEmail,
                message: {
                    subject: emailState.subject,
                    text: "MYQRALERT.COM",
                    html: htmlContent,
                },
            });
        } catch (error) {
            console.error("Error saving content to Firestore: ", error);
            db.collection("errors").add({
                timestamp: new Date(),
                errorDetails: error.message,
            });
        }
    };





    const handleEditorChange = (content, editor) => {
        console.log('Content was updated:', content);
    }

    useEffect(() => {
        db.collection("usuarios").onSnapshot((querySnapshot) => {
            const docs = []
            querySnapshot.docs.forEach(doc => {
                docs.push({ ...doc.data(), id: doc.id })
            })
            setUsers(docs)
        })
        db.collection("marketingContent").onSnapshot((querySnapshot) => {
            const docs = []
            querySnapshot.docs.forEach(doc => {
                docs.push({ ...doc.data(), id: doc.id })
            })
            setMarketingEmails(docs)
        })
    }, [])
    return (
        <div className="container-fluid">
            <div className="container shadow p-4">
                {
                    isLoading ?
                        <div className="container-fluid d-flex flex-column justify-content-center">
                            <div>
                                <h1 className="text-center fw-bold">Trabajando</h1>
                            </div>
                            <div className='justify-content-center text-center d-flex my-4'>
                                <div class="spinner-border" role="status"></div>
                            </div>
                        </div>
                        :
                        <div>
                            <h1 className="text-center fw-bold">Email Marketing</h1>
                            <div className='d-flex flex-row'>
                                <Link to={"/ad/dashboard"} className="btn btn-dark text-light fs-12">Volver</Link>
                            </div>
                            <div className="text-center my-4">
                                {
                                    showForm ?
                                        <div className="m-1">
                                            <div className="d-flex flex-row my-4">
                                                <button onClick={() => setShowForm(false)} className="btn btn-dark">Volver</button>
                                            </div>

                                            <div className="form-control my-4">
                                                <input type="text" placeholder='Asunto' onChange={(e) => handleEmailStateChange("subject", e.target.value)} className="form-control" />
                                            </div>
                                            <div className="container fade-div p-2">
                                                <div className="row row-cols-1">
                                                    <div className="col-12 col-md-12 shadow">
                                                        <Editor

                                                            apiKey='22jbxjprysa2pf0hkse6c03pmn7cnp2k9mibs1qwzhg140l1'
                                                            onInit={(evt, editor) => editorRef.current = editor}
                                                            init={{
                                                                toolbar: "undo redo | styleselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | code",
                                                                menubar: true,
                                                                statusbar: true,
                                                                plugins: 'lists link image code',
                                                                height: 300,
                                                                setup: (editor) => {
                                                                    editor.on('change', handleEditorChange)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="my-4">
                                                        <button className="btn btn-outline-dark fs-12" onClick={save}>Enviar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        :
                                        <div>
                                            <span className='text-dark text-center fs-6 fw-bold'>Selecciona destinatarios</span>

                                            {
                                                externalMails ?

                                                    <div className="container">
                                                        <div className='d-flex align-items-center'>
                                                            <input id='checkExterno' className="form-check-input" type="checkbox" checked={externalMails} onChange={() => handleExternalMails(true)} />
                                                            <label htmlFor="checkExterno" className='m-1'> Carga externa</label>
                                                        </div>
                                                        <div className="d-flex flex-row-reverse">
                                                            <button className="btn btn-outline-dark fs-12" onClick={handleContinue}>Continuar</button>
                                                        </div>
                                                        <div>
                                                            <span className='text-dark text-center fs-6 fw-bold'>Carga de correos externos, separados por coma</span>
                                                        </div>
                                                        <div className="form-control">
                                                            <textarea className='form-control' name="" id="" cols="100" rows="5" onChange={(e) => setExternalMailsList(e.target.value)}></textarea>
                                                        </div>
                                                    </div>
                                                    :
                                                    <CheckboxSelector users={users} handleSelection={handleSelection} handleExternalMails={handleExternalMails} handleContinue={handleContinue} />
                                            }
                                        </div>
                                }


                            </div>
                        </div>
                }

            </div>
        </div>
    )
}
