import React, { useEffect, useState } from 'react'
import app from '../../firebase';
import { Link } from 'react-router-dom';

const db = app.firestore();


export default function UsersConfig() {
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState('');
  const [startDateUser, setStartDateUser] = useState('')
  const [endDateUser, setEndDateUser] = useState('')
  const [endDate, setEndDate] = useState('');
  const [plan, setPlan] = useState('')
  const [emailUser, setEmailUser] = useState('')
  const [tagId, setTagId] = useState('')
  const [parametro, setParametro] = useState('');


  const [workingUsers, setWorkingUsers] = useState(false)
  const [workingOnUser, setWorkingOnUser] = useState(false)

  const [persons, setPersons] = useState([]);

  const [showPetsConfig, setShowPetsConfig] = useState(false)


  const convertToISODate = (date) => {
    // Divide la fecha en partes (día, mes, año)
    const [day] = date.split('/');

    // Formatea la fecha en el formato ISO deseado
    const isoDate = `${day}T00:00:00Z`;
    return isoDate;
  };

  const updateSubscriptionDates = () => {
    const batch = db.batch();
    const usuariosRef = db.collection('persons');
    setWorkingUsers(true)
    usuariosRef.get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const docRef = usuariosRef.doc(doc.id);
          const isoStartDate = convertToISODate(startDate);
          const isoEndDate = convertToISODate(endDate);

          // Agregar operaciones al batch
          batch.update(docRef, {
            fechaInicioSuscripcion: isoStartDate,
            fechaFinSuscripcion: isoEndDate,
            'plan': "Pro",
            'subscription.subscriptionStatus': 'ACTIVE'
          });
        });

        // Ejecutar el batch
        return batch.commit();
      })
      .then(() => {
        alert('Actualización de fechas de suscripción exitosa para los usuarios.');
        setWorkingUsers(false)
        window.location.reload()
      })
      .catch((error) => {
        alert('Error al actualizar fechas de suscripción: ', error);
        setWorkingUsers(false)
      });
  };


  const updateSubscriptionDatesForUser = () => {
    const petsRef = db.collection('persons');
    setWorkingOnUser(true)
    // Realiza una consulta para encontrar el documento del usuario por su correo electrónico
    petsRef.where('tagId', '==', tagId).get()
      .then((querySnapshot) => {
        if (querySnapshot.size === 0) {
          console.log(`No se encontró el tag ${tagId}`);
          return;
        }

        // Deberías manejar el caso en el que haya múltiples usuarios con el mismo correo electrónico
        // Aquí asumimos que solo hay uno
        const doc = querySnapshot.docs[0];
        const docRef = doc.ref;

        console.log(startDateUser, endDateUser)
        const isoStartDate = convertToISODate(startDateUser);
        const isoEndDate = convertToISODate(endDateUser);

        // Actualiza el documento del usuario encontrado
        return docRef.update({
          fechaInicioSuscripcion: isoStartDate,
          fechaFinSuscripcion: isoEndDate,
          'demoPro': true,
          'plan': 'Pro',
          'subscription.subscriptionStatus': 'ACTIVE'
        });
      })
      .then(() => {
        alert(`Actualización de fechas de suscripción exitosa`);
        setWorkingOnUser(false)
        window.location.reload()
      })
      .catch((error) => {
        alert(`Error al actualizar fechas de suscripción`, error);
        console.log(error.message)
        console.log(error)
        setWorkingOnUser(false)
      });
  };

  const addPlanToUsers = () => {
    // Inicializa un batch
    const batch = db.batch();

    // Obtén una referencia a la colección de usuarios
    const petsRef = db.collection('persons');

    petsRef.get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // Agrega una operación de actualización al batch para cada documento
          const docRef = petsRef.doc(doc.id);
          batch.set(docRef, { plan: "Demo" }, { merge: true });
        });

        // Ejecuta el batch para aplicar todas las actualizaciones de manera atómica
        return batch.commit();
      })
      .then(() => {
        console.log('Actualización de plan exitosa para todos los usuarios.');
      })
      .catch((error) => {
        console.error('Error al actualizar usuarios: ', error);
      });
  };



  const cancelDemoSubscription = async (tagId) => {

    if (window.confirm("Confirme que desea cancelar la suscripción")) {
      try {
        // Busca el documento en la colección persons con el tagId proporcionado
        const querySnapshot = await db.collection('persons').where('tagId', '==', tagId).get();

        // Verifica si se encontró algún documento
        if (querySnapshot.empty) {
          console.error(`No se encontró ningún person con el tagId: ${tagId}`);
          return;
        }



        // Actualiza el campo 'subscription.subscriptionStatus' a 'FALSE'
        const petDoc = querySnapshot.docs[0];
        const petId = petDoc.id;

        await db.collection('persons').doc(petId).update({
          plan: "Demo",
          fechaFinSuscripcion: 0,
          'subscription.subscriptionStatus': 'FALSE'
        });

        alert(`La suscripción del person con tagId ${tagId} ha sido cancelada.`);
      } catch (error) {
        alert('Error al cancelar la suscripción:', error);
      }
    }

  };

  useEffect(() => {
    setLoading(true);
    const unsubscribe = db.collection('persons')
      .where('subscription', '!=', null)
      .get()
      .then((querySnapshot) => {
        const petList = [];
        querySnapshot.forEach((doc) => {
          petList.push({ id: doc.id, ...doc.data() });
        });
        setPersons(petList);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error al obtener datos de Firestore:', error);
      });

    return () => unsubscribe(); // Detener la suscripción cuando el componente se desmonte
  }, []); // Asegúrate de pasar un arreglo vacío como dependencia si solo quieres que se ejecute una vez al montar el componente


  return (
    <div className='container p-4'>




      <div className="my-4">
        <Link to="/ad/dashboard" className='bg-dark text-decoration-none text-white bg-white p-2 fs-5'>◄ Regresar</Link>
      </div>
      <div className="my-4">
        <span className='display-5 fw-bold'>Suscripciones</span>
      </div>

      <div className="d-flex flex-row-reverse">

        <button className={`${!showPetsConfig ? "btn btn-dark text-white" : "btn btn-secondary text-white"}`} onClick={() => setShowPetsConfig(!showPetsConfig)}>
          {
            !showPetsConfig ? <span className='fade-div'>Abrir configuración de usuarios
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" style={{ width: "20px", height: "20px" }}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
              </svg>
            </span> : <span className='fade-div'>Cerrar configuración <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" style={{ width: "20px", height: "20px" }}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            </span>
          }
        </button>
      </div>
      {
        showPetsConfig ?
          <div className="row fade-div">
            <div className="col shadow my-4 m-4 p-4 border">
              <div class="my-4 alert alert-warning" role="alert">
                En este modulo puedes colocarle las fechas de inicio de su sucripción PRO a todos los usuarios actuales así como su fecha de expiración del plan
              </div>
              <div>
                <label>Fecha de Inicio (dd/mm/yyyy): </label>
                <input
                  className='form-control'
                  type="date"
                  value={startDate}
                  placeholder='Ej. 02/11/2023'
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div>
                <label>Fecha de Fin (dd/mm/yyyy): </label>
                <input
                  className='form-control'
                  type="date"
                  value={endDate}
                  placeholder='Ej. 02/01/2024'
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>

              {
                workingUsers ?
                  <button className='btn bg-orange text-light my-4 d-flex align-items-center'>
                    <span class="sr-only mx-2">Por favor espere mientras se realizan los cambios </span>
                    <div class="spinner-border spinner-border-sm text-light" role="status"></div>
                  </button>
                  :
                  <button className='btn bg-orange text-light my-4' onClick={updateSubscriptionDates}>Realizar cambios a todos los usuarios</button>
              }

            </div>
            {/* <div className="col shadow my-4 m-4 p-4 border">
              <div class="my-4 alert alert-warning" role="alert">
                Pasar todos los usuarios a PRO
              </div>
              <button className='btn bg-orange text-light my-4' onClick={addPlanToUsers}>Pasar a pro a todos los usuarios</button>
            </div> */}


            <div className="col shadow my-4 m-4 p-4 border">
              <div class="my-4 alert alert-primary" role="alert">
                En este modulo puedes colocarle las fechas de inicio de su sucripción PRO a una persona actual así como su fecha de expiración del plan
              </div>

              <div>
                <label>TagId de la persona</label>
                <input
                  className='form-control'
                  type="text"
                  onChange={(e) => setTagId(e.target.value)}
                />
              </div>

              <div>
                <label>Fecha de Inicio (dd/mm/yyyy): </label>
                <input
                  className='form-control'
                  type="date"
                  value={startDateUser}
                  placeholder='Ej. 02/11/2023'
                  onChange={(e) => setStartDateUser(e.target.value)}
                />
              </div>
              <div>
                <label>Fecha de Fin (dd/mm/yyyy): </label>
                <input
                  className='form-control'
                  type="date"
                  value={endDateUser}
                  placeholder='Ej. 02/01/2024'
                  onChange={(e) => setEndDateUser(e.target.value)}
                />
              </div>
              {
                workingOnUser ?
                  <button className='btn bg-orange text-light my-4 d-flex align-items-center'>
                    <span class="sr-only mx-2">Por favor espere mientras se realizan los cambios </span>
                    <div class="spinner-border spinner-border-sm text-light" role="status"></div>
                  </button>
                  :
                  <button className='btn bg-orange text-light my-4' onClick={updateSubscriptionDatesForUser}>Modificar suscripción del tag: {tagId}</button>
              }
            </div>
          </div>


          :
          null
      }


      <div>
        <h2>Listado de usuarios con suscripcion</h2>

        {
          loading ?
            <div className='containerDashboard'>
              <div class="spinner-border text-dark" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            :
            <div className="container">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th style={{ width: '25%' }}>Tag</th>
                    <th style={{ width: '25%' }}>Nombre</th>
                    <th style={{ width: '25%' }}>Email</th>
                    <th style={{ width: '25%' }}>Estado</th>
                    <th style={{ width: '25%' }}>Inicio</th>
                    <th style={{ width: '25%' }}>Vencimiento</th>
                    <th style={{ width: '25%' }}>Plan</th>
                    <th style={{ width: '25%' }}>Ver Tag</th>
                    <th style={{ width: '25%' }}>Cancelar</th>
                  </tr>
                </thead>
                <tbody>
                  {persons.map((person, index) => (
                    <tr key={index}>
                      <td>{person.tagId}</td>
                      <td>{person.nombre}</td>
                      <td>{person.email}</td>
                      <td>{person.subscription.subscriptionStatus}</td>
                      <td>{person.fechaInicioSuscripcion ? person.fechaInicioSuscripcion.split('T')[0] : person.subscription.subscription_startTime ? person.subscription.subscription_startTime.split('T')[0] : ''}</td>
                      <td>{person.fechaFinSuscripcion ? person.fechaFinSuscripcion.split('T')[0] : person.subscription.subscription_nextBillingTime ? person.subscription.subscription_nextBillingTime.split('T')[0] : ''}</td>
                      <td>{person.plan}</td>
                      <td><a href={`/person/${person.tagId}`} className='linkView'>Ver</a></td>
                      <td><button className='btn btn-danger' onClick={() => cancelDemoSubscription(person.tagId)}>Cancelar</button></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
        }

      </div>
    </div>
  );
}
