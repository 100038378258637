import React, { useEffect, useState } from 'react';
import FilterResults from 'react-filter-search';


const CheckboxSelector = ({ users, handleSelection, handleContinue, handleExternalMails }) => {
    const [selected, setSelected] = useState([]);
    const [parametro, setParametro] = useState('');

    const handleSelectAll = () => {
        if (selected.length === users.length) {
            setSelected([]);
        } else {
            setSelected(users);
        }
    }


    const handleCheckboxChange = (user) => {
        if (selected.includes(user)) {
            setSelected(selected.filter(selectedUser => selectedUser !== user));
        } else {
            setSelected([...selected, user]);
        }
    }




    const handleSearchUserInputChange = e => {
        setParametro(e.target.value)
    }

    const handleSendIndividualMails = () => {
        console.log("Individual mails")
    }


    useEffect(() => {
        handleSelection(selected)
    }, [selected])
    return (
        <div>
            <div className="d-flex flex-row my-4 justify-content-between">
                <div>
                    <div className='d-flex align-items-center'>
                        <input id='checkExterno' className="form-check-input" type="checkbox" onChange={() => handleExternalMails(true)} />
                        <label htmlFor="checkExterno" className='m-1'> Carga externa</label>
                    </div>
                    <button className="btn btn-outline-dark fs-12" onClick={handleSelectAll}>Seleccionar todos</button>
                    <button className="btn btn-outline-dark fs-12" onClick={() => handleContinue(true)}>Continuar ➤</button>
                </div>
            </div>
            <div className="container">
                <div className='my-2'>
                    <input placeholder="Buscar usuario" value={parametro} onChange={handleSearchUserInputChange} className="form-control shadow"></input>
                </div>
                <FilterResults
                    value={parametro}
                    data={users}
                    renderResults={results => (
                        results.length === 0 ?
                            <label className="text-left fw-bold form-check-label m-1" htmlFor="flexCheckDefault">No hay coincidencias</label>
                            :
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Nombre de usuario</th>
                                            <th>Email</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {results.map((user, index) => (
                                            <tr key={user.id}>
                                                <td>{index + 1}</td> {/* Aquí se muestra el número de fila */}
                                                <td>{user.userName}</td>
                                                <td>{user.userEmail}</td>
                                                <td>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value={user} id={user.id} checked={selected.includes(user)} onChange={() => handleCheckboxChange(user)} />
                                                        <label className="form-check-label" htmlFor={user.id}>Seleccionar</label>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                    )}
                />
            </div>

        </div>
    );
}

export default CheckboxSelector;