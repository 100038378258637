/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link, useNavigate } from 'react-router-dom';
/**Css styles */
import '../../../Navigation.css';

/**Transitions */

import app from '../../../firebase';
import FileUploader from '../FileUploader';
const db = app.firestore();


const GeneralDataForm = () => {

    // Navigation
    let navigate = useNavigate();


    // States
    const [state, setState] = useState({
        nombre: "",
        direccion: "",
        ciudad: "",
        pais: "",
        nacionalidad: "",
        cp: "",
        telMovil: "",
        telCasa: "",
        telOficina: ""
    });

    const [fileLink, setFileLink] = useState('');
    const [tagId, setTagId] = useState('');
    const [userId, setUserId] = useState('');

    // State handlers
    const handleChangeText = (name, value) => {
        setState({ ...state, [name]: value })
    }


    const savePerson = async () => {
        setFileLink(localStorage.getItem("@fileLink"));

        const userIDAux = localStorage.getItem("userId")
        if (state.nombre != "" && fileLink != "") {
            state["tagId"] = tagId;
            state["personPhotoImageLink"] = fileLink;
            state["userId"] = userIDAux;
            state["showTryForFree"] = true;
            state["plan"] = "Demo";

            await db.collection("persons").add(state).then(() => {
                localStorage.removeItem("@fileLink");
                updateTagIdIsActive();
            })

        } else {
            alert("Confirme que la información está completa e intente nuevamente");
        }
    }

    const updateTagIdIsActive = async () => {

        await db.collection("tags").where("tagId", "==", tagId).get().then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
                db.collection('tags').doc(doc.id).set({
                    "isActive": true,
                    "personId": userId,
                }, { merge: true }).then(() => {
                    navigate("/tag_saved");
                })
            });
        }).catch(function (error) {
            console.log("Error getting documents: ", error);
        });
    }

    useEffect(() => {
        setTagId(localStorage.getItem("@tagId"));
        setUserId(sessionStorage.getItem("userId"));
    }, [])


    return (


        <>

            <div className='d-flex flex-column justify-content-center' id='containerLogin'>
                <div className="container-myQrCard">
                    <span className='myQRAlertBlack'>My<span className='myQRAlert'>QR</span>Alert</span>
                </div>
                <div className='mt-4 login-card'>
                    <div className='m-4'>
                        <h2 className="firstText">Datos generales <span className='fieldRequired'>* Requerido</span></h2>
                        <div className='mt-2'>


                            <div className='form-group'>
                                <label className='mt-3'>Foto <span className='fieldRequired'>*</span></label>
                                <FileUploader></FileUploader>
                            </div>
                            <div className='form-group'>
                                <label className='mt-3'>Nombre <span className='fieldRequired'>*</span></label>
                                <input type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeText("nombre", e.target.value)}></input>
                            </div>
                            {/* <div className='form-group'>
                                <label className='mt-3'>Correo electrónico <span className='fieldRequired'>*</span></label>
                                <input type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeText("email", e.target.value)}></input>
                            </div>
                            <div className='form-group'>
                                <label className='mt-3'>Dirección</label>
                                <input type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeText("direccion", e.target.value)}></input>
                            </div>
                            <div className='form-group'>
                                <label className='mt-3'>Ciudad</label>
                                <input type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeText("ciudad", e.target.value)}></input>
                            </div>
                            <div className='form-group'>
                                <label className='mt-3'>País</label>
                                <input type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeText("pais", e.target.value)}></input>
                            </div>
                            <div className='form-group'>
                                <label className='mt-3'>Nacionalidad</label>
                                <input type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeText("nacionalidad", e.target.value)}></input>
                            </div>
                            <div className='form-group'>
                                <label className='mt-3'>C.P.</label>
                                <input type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeText("cp", e.target.value)}></input>
                            </div>
                            <div className='form-group'>
                                <label className='mt-3'>Tel. Móvil.</label>
                                <input type="number" class="form-control mb-2 mt-2" onChange={(e) => handleChangeText("telMovil", e.target.value)}></input>
                            </div>
                            <div className='form-group'>
                                <label className='mt-3'>Tel. Casa</label>
                                <input type="number" class="form-control mb-2 mt-2" onChange={(e) => handleChangeText("telCasa", e.target.value)}></input>
                            </div>
                            <div className='form-group'>
                                <label className='mt-3'>Tel. Oficina</label>
                                <input type="number" class="form-control mb-2 mt-2" onChange={(e) => handleChangeText("telOficina", e.target.value)}></input>
                            </div> */}
                        </div>
                    </div>

                    <div className='d-flex flex-row flex-column container-group mb-4'>
                        <button onClick={() => savePerson()} className="btn btnBlack mb-2">Registrar tag</button>
                        <Link to={"/cl/dashboard"} className="linkView mt-2" style={{ color: "#000" }}>Cancelar</Link>
                    </div>
                </div>
            </div>
        </>
    );



}


export default GeneralDataForm;