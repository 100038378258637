import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import app from '../../../firebase';

const db = app.firestore();

export default function AdminEmails() {
    const [emails, setEmails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
        const unsubscribe = db
            .collection('mail')
            .orderBy('delivery.startTime', 'desc')
            .limit(limit || 10) // Limitar la consulta inicial a 10 correos electrónicos
            .onSnapshot((querySnapshot) => {
                const docs = [];
                querySnapshot.forEach((doc) => {
                    docs.push({ ...doc.data(), id: doc.id });
                });
                setEmails(docs);
                setIsLoading(false);
            });

        return () => unsubscribe();
    }, [limit]);


    const formatDate = (date) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(date.seconds * 1000).toLocaleDateString(undefined, options);
    };

    const [searchTerm, setSearchTerm] = useState('');

    return (
        <div className="containerDashboard">
            {isLoading ? (
                <div className="spinner-border text-dark" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            ) : (
                <div className='containerDashboard p-4'>
                    <div className='w-100'>

                        <div className="my-4">
                            <span className="text-dark fw-bold display-6">Emails enviados</span>
                        </div>
                        <div className="flex my-4">
                            <Link to="/ad/dashboard" className="bg-dark text-decoration-none text-white bg-white p-2 fs-5">
                                ◄ Regresar
                            </Link>
                        </div>
                    </div>

                    <div className="table-responsive">


                        <input
                            type="text"
                            placeholder="Buscar..."
                            value={searchTerm}
                            className='form-control mb-4 w-50-responsive'
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <input
                            type="number"
                            placeholder={`Correos a mostrar`}
                            value={limit}
                            className='form-control mb-4 w-50-responsive'
                            onChange={(e) => setLimit(e.target.value)}
                        />


                        <table className="table">
                            {/* Encabezados de la tabla */}
                            <thead>
                                <tr>
                                    <th>Estado</th>
                                    <th>Inició</th>
                                    <th>Finalizó</th>
                                    <th>Destino</th>
                                    <th>Text</th>
                                    <th>Asunto</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            {/* Cuerpo de la tabla */}
                            <tbody>


                                {
                                    emails.filter((row) =>
                                        row.delivery.state.includes(searchTerm) ||
                                        formatDate(row.delivery.startTime).includes(searchTerm) ||
                                        formatDate(row.delivery.endTime).includes(searchTerm) ||
                                        row.to.includes(searchTerm) ||
                                        row.message.text.includes(searchTerm) ||
                                        row.message.subject.includes(searchTerm)
                                    ).map((filteredRow, index) => (
                                        <tr key={index} id={filteredRow.id}>
                                            <td>{filteredRow.delivery.state == 'SUCCESS' ? <span className='text-success bg-success rounded-2xl'>Entregado</span> : <span className='text-danger'>No entregado</span>}</td>
                                            <td>{formatDate(filteredRow.delivery.startTime)}</td>
                                            <td>{formatDate(filteredRow.delivery.endTime)}</td>
                                            <td>{filteredRow.to}</td>
                                            <td>{filteredRow.message.text}</td>
                                            <td>{filteredRow.message.subject}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                <a target="_blank" href={`/ad/emails/show/${filteredRow.id}`}>
                                                    <button className="btn btn-dark">Abrir</button>
                                                </a>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    {/* Mostrar el indicador de carga */}
                    {isLoading && <div>Loading...</div>}
                </div>
            )}
        </div>
    );
}
