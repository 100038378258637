/**React imports */
import React, { useEffect, useState } from 'react';
/**Router */
import { useParams, Link } from 'react-router-dom';
/**Css styles */
import '../../Navigation.css';
import emailjs from 'emailjs-com'

import app from '../../firebase';

//importing icons
import whatsAppIcon from '../../assets/icons/whatsAppIcon.svg';

const db = app.firestore();


const PersonInfoGeneral = () => {

    let params = useParams();

    const [personData, setPersonData] = useState('');

    const [isLoading, setIsLoading] = useState(true);



    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;




    function obtenerHoraExacta() {
        const ahora = new Date();
        let horas = ahora.getHours();
        const minutos = ahora.getMinutes();
        const periodo = horas >= 12 ? 'p.m.' : 'a.m.';

        // Convierte a formato de 12 horas
        horas = horas % 12 || 12;

        // Formatea los minutos para asegurar que siempre haya dos dígitos
        const minutosFormateados = minutos < 10 ? '0' + minutos : minutos;

        // Construye la cadena de la hora
        const horaExacta = `${horas}:${minutosFormateados} ${periodo}`;

        return horaExacta;
    }

    const timenow = () => {
        var now = new Date(),
            ampm = 'am',
            h = now.getHours(),
            m = now.getMinutes(),
            s = now.getSeconds();
        if (h >= 12) {
            if (h > 12) h -= 12;
            ampm = 'pm';
        }

        if (m < 10) m = '0' + m;
        if (s < 10) s = '0' + s;
        return now.toLocaleDateString() + ' ' + h + ':' + m + ':' + s + ' ' + ampm;
    }



    useEffect(() => {

        db.collection('persons').where("tagId", "==", params.personId).onSnapshot((querySnapshot) => {
            querySnapshot.docs.forEach((doc) => {
                setPersonData(doc.data())
                setIsLoading(false)
                navigator.geolocation.getCurrentPosition((pos) => {

                    if (doc.data().correosEmergencia !== undefined) {
                        db.collection("mail")
                            .add({
                                to: doc.data().correosEmergencia,
                                message: {
                                    subject: "SE HA LOCALIZADO A " + doc.data().nombre,
                                    text: "LECTURA DE QR",
                                    html: `
                                <p>Se ha registrado la lectura de la placa pertenenciente a: ${doc.data().nombre}</p>
                                <p>Se proporcionan los datos de la lectura, la ubicación es aproximada y depende del dispositivo que ha leido el tag</p>
                                <p>&nbsp;</p>
                                <p style="padding-left: 40px;">Ubicación: https://www.google.com/maps/place/${pos.coords.latitude},${pos.coords.longitude}</p>
                                <p style="padding-left: 40px;">Fecha de lectura de QR: ${timenow()}</p>
                                <p style="padding-left: 40px;">tagId:${params.personId}</p>
                                <p style="padding-left: 40px;">&nbsp;</p>
                                <center>
                                <p><img src="https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/LOGO-PNG.ico?alt=media&amp;token=0743be12-56ea-4341-957c-f0501675355f" alt="" width="100" height="100"></p>
                                </center>
                                `,
                                },
                            }).then(() => console.log("::ALERT SENT SUCCESSFULLY::"));



                        if (doc.data().numeroWhatsApp !== undefined) {
                            const horaExacta = obtenerHoraExacta();

                            var myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/json");
                            myHeaders.append("Authorization", "Bearer EAADL4h0Q9ZBIBO4GBV0Y2KtJZB4GY9Qq5SkQ5VkUAdpmmYlYQWa79dHAXH5oRW0E1KHBZCqHFzwfKSG1NA3vDfnJSDR0K5RwUQIS4ZAuOcDZAICpGeFAoBA9b8QScBydGEjkZAs736ICqGtZAxYeMy2LXnQACbzeLZApsK3ZC3masC9ACWJ5U20rLFAJ6lTa2ks7NfMSCmVsZAW0lsMkXU");

                            const raw_1 = JSON.stringify({
                                "messaging_product": "whatsapp",
                                "to": doc.data().numeroWhatsApp,
                                "text": {
                                    "preview_url": true,
                                    "body": `Se ha escaneado la placa perteneciente a: *${doc.data().nombre}* 👤 a las: *${horaExacta}* \n\nSe proporcionan los datos de la lectura, la ubicación es aproximada y depende del dispositivo que ha leído el tag \n\ntagId: *${params.personId}* \n\n📍Ubicación: 👉 https://www.google.com/maps/place/${pos.coords.latitude},${pos.coords.longitude}`
                                }
                            })

                            const raw = JSON.stringify({
                                "messaging_product": "whatsapp",
                                "recipient_type": "individual",
                                "to": doc.data().numeroWhatsApp,
                                "type": "interactive",
                                "interactive": {
                                    "type": "button",
                                    "body": {
                                        "text": `Se ha escaneado la placa perteneciente a: \n\n*${doc.data().nombre}* 👤 a las: *${horaExacta}* \n\nSe proporcionan los datos de la lectura, la ubicación es aproximada y depende del dispositivo que ha leído el tag \n\n*tagId*: ${params.personId} \n\n📍 Ubicación: 👉\n\nhttps://www.google.com/maps/place/${pos.coords.latitude},${pos.coords.longitude} 🗺️\n\n _Para continuar recibiendo alertas, da click en *Permitir alertas*_`
                                    },
                                    "action": {
                                        "buttons": [
                                            {
                                                "type": "reply",
                                                "reply": {
                                                    "id": "1",
                                                    "title": "Permitir Alertas"
                                                }
                                            }
                                        ]
                                    }
                                },
                                "preview_url": true
                            });
    
                            
                            const requestOptions_1 = {
                                method: "POST",
                                headers: myHeaders,
                                body: raw,
                                redirect: "follow"
                            };

                            try {
                                fetch("https://graph.facebook.com/v19.0/213143195208157/messages", requestOptions_1)
                                    .then(() => {
                                        console.log("WhatsApp Alert Sent")
                                    })
                            } catch (error) {
                                console.log(error)
                            }
                        } else {
                            console.log("Se terminó el proceso de acuerdo a las caracteristicas Demo");
                        }

                    }



                })
            })
        })


    }, []);

    return (

        <>

            {
                isLoading == true ?
                    <>
                        <div className='containerDashboard'>
                            <div className="my-4">
                                <span className='display-6 text-dark fw-bold'>No se ha encontrado información del tag</span>
                            </div>
                            <div class="spinner-border text-dark" role="status">
                                <span class="visually-hidden">Cargando información</span>
                            </div>
                        </div>
                    </>

                    :

                    <div className="containerDashboard">

                        {/* Foto */}

                        <h2 className='userNameText' id="row-pc">Detalles de {personData.nombre}</h2>

                        <div className="containerPersonPhoto" id="row-pc">
                            <div style={{ backgroundImage: `url(${personData.personPhotoImageLink})`, borderRadius: "50%", width: "200px", height: "200px" }} className="pet-circle-image-details shadow my-2"></div>
                        </div>


                        <div className="shadow-card-image my-4" id='row-mb'>
                            <center>
                                <div style={{ backgroundImage: `url(${personData.personPhotoImageLink})`, borderRadius: "50%", width: "200px", height: "200px" }} className="pet-circle-image-details shadow my-2"></div>

                                <div className="containerNameMb">
                                    <span className='nameUserCard'>
                                        {personData.nombre}
                                    </span>
                                </div>

                            </center>
                        </div>
                        <>
                            {
                                personData.plan !== "Demo" ?
                                    <>
                                        {
                                            personData.visibleInformacionVital == true ?
                                                <div className="container-vital-info p-2">
                                                    <span className='display-6 text-dark fw-bold'>{personData.informacionVital}</span>
                                                </div>
                                                :
                                                <div className='divider'></div>
                                        }

                                        {/* Fin información vital */}

                                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2">

                                            {
                                                personData.visibleDatosGenerales == true ?

                                                    <div className="col my-4">
                                                        <div class="accordion-item containerButtonAcordion">
                                                            <h2 class="accordion-header" id="headingOne">
                                                                <button class="buttonCardAcordion accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                    Ver datos generales
                                                                </button>
                                                            </h2>
                                                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                <div class="accordion-body">

                                                                    <div className='m-2'>
                                                                        <span className='titleText'>Nombre: </span><span className='dataText'>{personData.nombre}</span>
                                                                    </div>
                                                                    <div className='m-2'>
                                                                        <span className='titleText'>Dirección: </span><span className='dataText'>{personData.direccion}</span>
                                                                    </div>
                                                                    <div className='m-2'>
                                                                        <span className='titleText'>País: </span><span className='dataText'>{personData.pais}</span>
                                                                    </div>
                                                                    <div className='m-2'>
                                                                        <span className='titleText'>Ciudad: </span><span className='dataText'>{personData.ciudad}</span>
                                                                    </div>

                                                                    <div className='m-2'>
                                                                        <span className='titleText'>C.P.: </span><span className='dataText'>{personData.cp}</span>
                                                                    </div>


                                                                    <div className='m-2'>
                                                                        <span className='titleText'>Tel. Móvil: </span><span className='dataText'>{personData.telMovil}</span>
                                                                    </div>

                                                                    <div className='m-2'>
                                                                        <span className='titleText'>Tel. Oficina: </span><span className='dataText'>{personData.telOficina}</span>
                                                                    </div>

                                                                    <div className='m-2'>
                                                                        <span className='titleText'>Tel. Casa: </span><span className='dataText'>{personData.telCasa}</span>
                                                                    </div>

                                                                    <div className='m-2'>
                                                                        <span className='titleText'>E-mail: </span><span className='dataText'>{personData.email}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    :
                                                    <div className='divider'></div>

                                            }



                                            {
                                                personData.visibleAvisarA == true ?

                                                    <div className="col my-4">
                                                        <div class="accordion-item containerButtonAcordion">
                                                            <h2 class="accordion-header" id="headingTwo">
                                                                <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                    Avisar a
                                                                </button>
                                                            </h2>
                                                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                                <div class="accordion-body">

                                                                    {

                                                                        personData.avisarA != undefined ?

                                                                            personData.avisarA.map((contacto) => (
                                                                                <>
                                                                                    <div className="row row-cols-2">
                                                                                        <div className="col">
                                                                                            <div className='m-2'>
                                                                                                <span className='titleText'>Nombre: </span><span className='dataText'>{contacto.nombre}</span>
                                                                                            </div>
                                                                                            <div className='m-2'>
                                                                                                <span className='titleText text-green'>Telefono: </span><a target={"_blank"} href={`tel:${contacto.telefono}`} className='dataText text-success'>{contacto.telefono}</a>
                                                                                            </div>
                                                                                            <div className='m-2'>
                                                                                                <span className='titleText'>Teléfono alternativo: </span><a target={"_blank"} href={`tel:${contacto.telefonoAlternativo}`} className='dataText text-success'>{contacto.telefonoAlternativo}</a>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col">
                                                                                            <a target={"_blank"} href={`https://api.whatsapp.com/send?phone=+52${contacto.telefono}`}>
                                                                                                <img src={whatsAppIcon} className="whatsAppIcon" alt="" />
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            ))

                                                                            :

                                                                            <div className='divider'></div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    :
                                                    <div className='divider'></div>

                                            }


                                            {
                                                personData.visiblePersonalData == true ?

                                                    <div className="col my-4">
                                                        <div class="accordion-item containerButtonAcordion">
                                                            <h2 class="accordion-header" id="headingThree">
                                                                <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                    Datos personales
                                                                </button>
                                                            </h2>
                                                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                                <div class="accordion-body">

                                                                    <div className='m-2'>
                                                                        <span className='titleText'>Fecha de nacimiento: </span><span className='dataText'>{personData.fechaNacimiento}</span>
                                                                    </div>
                                                                    <div className='m-2'>
                                                                        <span className='titleText'>Peso (kg): </span><span className='dataText'>{personData.peso}</span>
                                                                    </div>
                                                                    <div className='m-2'>
                                                                        <span className='titleText'>Altura (m): </span><span className='dataText'>{personData.altura}</span>
                                                                    </div>
                                                                    <div className='m-2'>
                                                                        <span className='titleText'>Grupo Sangúineo: </span><span className='dataText'>{personData.grupoSanguineo}</span>
                                                                    </div>

                                                                    <div className='m-2'>
                                                                        <span className='titleText'>Donante de Órganos: </span><span className='dataText'>{personData.donanteOrganos}</span>
                                                                    </div>


                                                                    <div className='m-2'>
                                                                        <span className='titleText'>Donante de Sangre: </span><span className='dataText'>{personData.donanteSangre}</span>
                                                                    </div>

                                                                    <div className='m-2'>
                                                                        <span className='titleText'>Número de la Seguridad Social: </span><span className='dataText'>{personData.numeroSeguridadSocial}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    :
                                                    <div className='divider'></div>

                                            }

                                            {
                                                personData.visibleHistorialClinico == true ?

                                                    <div className="col my-4">
                                                        <div class="accordion-item containerButtonAcordion">
                                                            <h2 class="accordion-header" id="headingFour">
                                                                <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                    Historial clínico
                                                                </button>
                                                            </h2>
                                                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                                <div class="accordion-body">

                                                                    <div className='m-2'>
                                                                        <span className='titleText'>Religión: </span><span className='dataText'>{personData.religion}</span>
                                                                    </div>
                                                                    <div className='m-2'>
                                                                        <span className='titleText'>Alcohol: </span><span className='dataText'>{personData.alcohol}</span>
                                                                    </div>
                                                                    <div className='m-2'>
                                                                        <span className='titleText'>Tabaco </span><span className='dataText'>{personData.tabaco}</span>
                                                                    </div>
                                                                    <div className='m-2'>
                                                                        <span className='titleText'>Seguro: </span><span className='dataText'>{personData.nombreSeguro}</span>
                                                                    </div>

                                                                    <div className='m-2'>
                                                                        <span className='titleText'>Nº de póliza: </span><span className='dataText'>{personData.numeroPoliza}</span>
                                                                    </div>

                                                                    <div className='m-2'>
                                                                        <span className='titleText'>Teléfono de la compañía: </span><span className='dataText'>{personData.telefonoDeSeguro}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    :
                                                    <div className='divider'></div>

                                            }

                                            {
                                                personData.visibleCondicionesMedicas == true ?

                                                    <div className="col my-4">
                                                        <div class="accordion-item containerButtonAcordion">
                                                            <h2 class="accordion-header" id="headingFive">
                                                                <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                                    Condiciones médicas
                                                                </button>
                                                            </h2>
                                                            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                                <div class="accordion-body">

                                                                    <div className='m-2'>
                                                                        <span className='titleText'>Enfermedades: </span><span className='dataText'>{personData.enfermedades}</span>
                                                                    </div>
                                                                    <div className='m-2'>
                                                                        <span className='titleText'>Vacunas: </span><span className='dataText'>{personData.vacunas}</span>
                                                                    </div>
                                                                    <div className='m-2'>
                                                                        <span className='titleText'>Alergias </span><span className='dataText'>{personData.alergias}</span>
                                                                    </div>
                                                                    <div className='m-2'>
                                                                        <span className='titleText'>Problemas de circulación sanguínea: </span><span className='dataText'>{personData.problemasCirculacion}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='divider'></div>

                                            }

                                            {
                                                personData.visibleAlergias ?

                                                    <div className="col my-4">
                                                        <div class="accordion-item containerButtonAcordion">
                                                            <h2 class="accordion-header" id="headAlergias">
                                                                <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAlergias" aria-expanded="false" aria-controls="collapseAlergias">
                                                                    Alergias
                                                                </button>
                                                            </h2>
                                                            <div id="collapseAlergias" class="accordion-collapse collapse" aria-labelledby="headAlergias" data-bs-parent="#collapseAlergias">
                                                                <div class="accordion-body">
                                                                    {

                                                                        personData.alergiasLista != undefined ?

                                                                            personData.alergiasLista.map((alergia) => (
                                                                                <>
                                                                                    <div className="container" key={alergia.id}>

                                                                                        <div className="row row-cols-1">
                                                                                            <div className="col">
                                                                                                <div className='m-2'>
                                                                                                    <span className='titleText'>Alergia: </span><span className='dataText'>{alergia.alergia}</span>
                                                                                                </div>
                                                                                                <div className='m-2'>
                                                                                                    <span className='titleText'>Reacción: </span> <span className='dataText'>{alergia.reaccion}</span>
                                                                                                </div>
                                                                                                <div className='m-2'>
                                                                                                    <span className='titleText'>Tratamiento: </span><span className='dataText'>{alergia.tratamiento}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>

                                                                                    <div className='divider'></div>
                                                                                </>
                                                                            ))

                                                                            :

                                                                            <div className='divider'></div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="divider"></div>
                                            }


                                            {
                                                personData.visibleMedicos == true ?

                                                    <div className="col my-4">
                                                        <div class="accordion-item containerButtonAcordion">
                                                            <h2 class="accordion-header" id="headingSix">
                                                                <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                                    Médicos
                                                                </button>
                                                            </h2>
                                                            <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                                                <div class="accordion-body">

                                                                    {

                                                                        personData.medicos != undefined ?

                                                                            personData.medicos.map((medico) => (
                                                                                <>
                                                                                    <div className="container" key={medico.id}>

                                                                                        <div className="row row-cols-1">
                                                                                            <div className="col">
                                                                                                <div className='m-2'>
                                                                                                    <span className='titleText'>Nombre: </span><span className='dataText'>{medico.nombre}</span>
                                                                                                </div>
                                                                                                <div className='m-2'>
                                                                                                    <span className='titleText'>Especialidad: </span><span className='dataText'>{medico.especialidad}</span>
                                                                                                </div>
                                                                                                <div className='m-2'>
                                                                                                    <span className='titleText'>Telefono: </span><span className='dataText'>{medico.telefonoMedico}</span>
                                                                                                </div>
                                                                                                <div className='m-2'>
                                                                                                    <span className='titleText'>Dirección: </span><span className='dataText'>{medico.direccionMedico}</span>
                                                                                                </div>
                                                                                                <div className='m-2'>
                                                                                                    <span className='titleText'>Ciudad: </span><span className='dataText'>{medico.ciudadMedico}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>

                                                                                    <div className='divider'></div>
                                                                                </>
                                                                            ))

                                                                            :

                                                                            <div className='divider'></div>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='divider'></div>

                                            }

                                            {
                                                personData.visibleMedicacion == true ?

                                                    <div className="col my-4">
                                                        <div class="accordion-item containerButtonAcordion">
                                                            <h2 class="accordion-header" id="headingSeven">
                                                                <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                                    Medicación
                                                                </button>
                                                            </h2>
                                                            <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                                                <div class="accordion-body">

                                                                    {

                                                                        personData.medicacion != undefined ?

                                                                            personData.medicacion.map((medicamento) => (
                                                                                <>
                                                                                    <div className="container" key={medicamento.id}>

                                                                                        <div className="row row-cols-1">
                                                                                            <div className="col">
                                                                                                <div className='m-2'>
                                                                                                    <span className='titleText'>Medicamento: </span><span className='dataText'>{medicamento.nombre}</span>
                                                                                                </div>
                                                                                                <div className='m-2'>
                                                                                                    <span className='titleText'>Dosis: </span><span className='dataText'>{medicamento.dosis}</span>
                                                                                                </div>
                                                                                                <div className='m-2'>
                                                                                                    <span className='titleText'>Frecuencia: </span><span className='dataText'>{medicamento.frecuencia}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>

                                                                                    <div className='divider'></div>
                                                                                </>
                                                                            ))

                                                                            :

                                                                            <div className='divider'></div>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='divider'></div>

                                            }
                                        </div>
                                    </>
                                    :
                                    null
                            }

                        </>


                    </div>
            }
        </>


    );

}


export default PersonInfoGeneral;