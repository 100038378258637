export const getCurrentDateTime = () => {
    const now = new Date();
    const date = now.toISOString().substring(0, 10);
    const time = now.toLocaleTimeString();
    return `${date} ${time}`;
}

export function getYouTubeVideoId(link) {
    const url = new URL(link);
    const id = url.pathname.split("/").pop();
    return id;
}

export const now = () => {
    const currentDate = new Date();
    return currentDate;
}

export function calcularFechaExpiracion(fechaActual, nombrePlan) {
    const fecha = new Date(fechaActual);

    if (nombrePlan === 'Mensual') {
        fecha.setMonth(fecha.getMonth() + 1);
    } else if (nombrePlan === 'Semestral') {
        fecha.setMonth(fecha.getMonth() + 6);
    } else if (nombrePlan === 'Demo') {
        fecha.setDate(fecha.getDate() + 14);
    }

    return fecha;
}


export const getBearerAccessToken = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", "Basic QVhqcWczWl81WjVHa3FwVDRqQjF6QU9DQ3dlMGE3cmpRdndJNkNTZU50aXNoTW53aWRNeW93ZlNEcjZkcjVFLTU2Y3RfU21Wc1YxeThmWEU6RVBuUVFnMXVBUDRPQVJIUGFLUEZ4X05EVmNRcnctVUs1eFZ4VkhMWGpaTFRnaFpsQlJQYTJZMHlIMDBRUExIY1VLbEpESXo1dmJSRDN2a2M=");

    var urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "client_credentials");

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const response = await fetch("https://api-m.paypal.com/v1/oauth2/token?_ga=2.197096236.1638088848.1692680276-2047062090.1685468217", requestOptions);
    const result = await response.json();
    return result.access_token;
}


export function obtenerFechaEnFormato() {
    const fecha = new Date();
    const anio = fecha.getFullYear();
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Agrega un cero inicial si es necesario
    const dia = fecha.getDate().toString().padStart(2, '0'); // Agrega un cero inicial si es necesario
    const hora = fecha.getHours().toString().padStart(2, '0'); // Agrega un cero inicial si es necesario
    const minutos = fecha.getMinutes().toString().padStart(2, '0'); // Agrega un cero inicial si es necesario
    const segundos = fecha.getSeconds().toString().padStart(2, '0'); // Agrega un cero inicial si es necesario

    // Formatea la fecha en el formato deseado
    const fechaFormateada = `${anio}-${mes}-${dia}T${hora}:${minutos}:${segundos}Z`;

    return fechaFormateada;
}

export function calcularFechaDeVencimiento(tipoPlan) {
    const fecha = new Date(); // Fecha actual
    if (tipoPlan === 'mensual') {
        fecha.setMonth(fecha.getMonth() + 1); // Agregar 1 mes
    } else if (tipoPlan === 'anual') {
        fecha.setMonth(fecha.getMonth() + 12); // Agregar 6 meses
    } else {
        return 'Tipo de plan no válido'; // Tipo de plan desconocido
    }

    // Formatea la fecha en el formato deseado
    const anio = fecha.getFullYear();
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const dia = fecha.getDate().toString().padStart(2, '0');
    const hora = fecha.getHours().toString().padStart(2, '0');
    const minutos = fecha.getMinutes().toString().padStart(2, '0');
    const segundos = fecha.getSeconds().toString().padStart(2, '0');
    const fechaFormateada = `${anio}-${mes}-${dia}T${hora}:${minutos}:${segundos}Z`;

    return fechaFormateada;
}


export const timeStampToDate = (timeStamp) => {
    // Obtiene timestamp y lo convierte a fecha
    const seconds = timeStamp.seconds;
    const nanoseconds = timeStamp.nanoseconds;
    const currentDate = new Date(0); // Crear objeto Date con valor inicial 0
    currentDate.setSeconds(seconds); // Establecer los segundos
    currentDate.setMilliseconds(nanoseconds / 1000000); // Establecer los milisegundos (nanosegundos convertidos a milisegundos)

    // Devolver la fecha como una cadena de texto en el formato deseado
    return currentDate.toLocaleDateString(); // O currentDate.toLocaleString() para incluir la hora y zona horaria
}