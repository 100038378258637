import { useEffect, useState } from 'react';
import { addSuscriptionDataToPet, getUserDataByUserId } from '../../../utils/suscriptions'
import { calcularFechaDeVencimiento, obtenerFechaEnFormato } from '../../../utils/tools';

const SuscriptionCardMonthly = ({ petId }) => {


    const getUserLiveAccessToken = async () => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Basic QWRyWDJhVndnX3RrZzZfWHZ0R25WSnhEMU5fTDBFMzVBUmUwcUVKZ09Yend0eVZ2T29kRENQTUM1WnRaVm5FT1NHMjMwX3pnLWxibW9xN086RUI3Q1lEYmtTTXQ3WjBEWXVWS0Y0LVM1ajE2S24tb21HVjF4Y3EzWlFjSmNqSGM5RDRfN29KUG5uTXZmM3pnSEpteTNiOFpBaFhJTlJoN1E=");
        myHeaders.append("Cookie", "l7_az=ccg14.slc");

        var urlencoded = new URLSearchParams();
        urlencoded.append("grant_type", "client_credentials");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        const response = await fetch("https://api-m.paypal.com/v1/oauth2/token?_ga=2.65717486.1103211079.1704223545-111234845.1700034201", requestOptions);
        const result = await response.json();
        return result.access_token;
    }

    const getBearerAccessToken = async () => {

        const access_token = await getUserLiveAccessToken();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", `Basic ${access_token}`);

        var urlencoded = new URLSearchParams();
        urlencoded.append("grant_type", "client_credentials");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        const response = await fetch("https://api-m.paypal.com/v1/oauth2/token?_ga=2.197096236.1638088848.1692680276-2047062090.1685468217", requestOptions);
        const result = await response.json();
        return result.access_token;
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.paypal.com/sdk/js?client-id=AdrX2aVwg_tkg6_XvtGnVJxD1N_L0E35ARe0qEJgOXzwtyVvOodDCPMC5ZtZVnEOSG230_zg-lbmoq7O&vault=true&intent=subscription';
        script.async = true;
        script.onload = () => {
            window.paypal.Buttons({
                style: {
                    shape: 'pill',
                    color: 'gold',
                    layout: 'vertical',
                    label: 'subscribe'
                },
                createSubscription: function (data, actions) {
                    return actions.subscription.create({
                        /* Creates the subscription */
                        plan_id: 'P-8P9350192T1802634MYLA6WY'
                    });
                },
                onApprove: async function (data, actions) {

                    const fechaFormateada = obtenerFechaEnFormato();
                    const nextBillingTime = calcularFechaDeVencimiento('mensual');

                    const subscriptionData = {
                        subscriptionStatus: "ACTIVE",
                        subscriptionID: data.subscriptionID,
                        subscription_startTime: fechaFormateada,
                        subscription_nextBillingTime: nextBillingTime,
                    }
                    await addSuscriptionDataToPet(subscriptionData, "Mensual", petId)
                }
            }).render('#paypal-button-container-P-8P9350192T1802634MYLA6WY'); // Renders the PayPal button
        };
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className='w-100' id="paypal-button-container-P-8P9350192T1802634MYLA6WY"></div>
    );
};

export default SuscriptionCardMonthly;